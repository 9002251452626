import { useState, useRef} from 'react'


import 'bootstrap/dist/css/bootstrap.css'

import IconButton from "@mui/material/IconButton"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

import '../components/flex.css'
import '../components/login.css'

import axios from 'axios'
import serverUrl from '../config'

import {
	RegExpMatcher,
	englishDataset,
	englishRecommendedTransformers
}  from 'obscenity'


const Signup = () => {


  const [showPassword, setShowPassword] = useState(false)

  const [subUser, changeUser] = useState('')
  const [subEmail, changeEmail] = useState('')
  const [subPassword, changePassword] = useState('')

  const [remember, changeRemember] = useState(false)

  const [warning, setWarning] = useState('')

  const checkSignup = useRef(true)

  const matcher = new RegExpMatcher({
    ...englishDataset.build(),
    ...englishRecommendedTransformers
  });

  
  const submitSignup =(e, subUser, subEmail, subPassword)=>{

    e.preventDefault()

    const regexEmail = /^[A-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[A-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[A-Z0-9-]+(?:\.[A-Z0-9-]+)*$/i

    const regexUser = /^[a-zA-Z0-9'_-]+$/

    const regexPassword = /^[^\s]+$/


    const regexUpper = /([A-Z])+/g
    
    const regexLower = /([a-z])+/g

    // upper removes uppercase letters, lower removes lowercase letters

    const subUserUpper = subUser.replaceAll(regexUpper, '')

    const subUserLower = subUser.replaceAll(regexLower, '')

    const subEmailUpper = subEmail.replaceAll(regexUpper, '')

    const subEmailLower = subEmail.replaceAll(regexLower, '')



    // username
    if(subUser === ''){
      setWarning('A username is required')
      checkSignup.current = false
    } 
    else if(subUser.length < 2 || subUser.length > 20){
      setWarning('Username must be between 2 and 20 characters')
      checkSignup.current = false
    }
    else if(!regexUser.test(subUser)){
      setWarning(`Valid username characters are A-Z 0-9 '_-`)
      checkSignup.current = false
    }
    // email
    else if(subEmail === ''){
      setWarning('An email is required')
      checkSignup.current = false
    }
    else if(subEmail.length > 256){
      setWarning('Email cannot exceed 256 characters')
      checkSignup.current = false
    } 
    else if(!regexEmail.test(subEmail)){
      setWarning('Please enter a valid email')
      checkSignup.current = false
    }
    // password
    else if(subPassword === ''){
      setWarning('A password is required')
      checkSignup.current = false
    }   
    else if(subPassword.length > 256){
      setWarning('Password cannot exceed 256 characters')
      checkSignup.current = false
    }
    else if(subPassword.length < 8 ){
      setWarning('Password must be at least 8 characters')
      checkSignup.current = false
    }
    else if(!regexPassword.test(subPassword) ){
      setWarning('Password cannot include spaces')
      checkSignup.current = false
    }
    else if (matcher.hasMatch(subUser) || matcher.hasMatch(subUserLower) || matcher.hasMatch(subUserUpper)) {
      checkSignup.current = false
      setWarning('Invalid username')
    }
    else if (matcher.hasMatch(subEmail) || matcher.hasMatch(subEmailLower) || matcher.hasMatch(subEmailUpper)) {
      checkSignup.current = false
      setWarning('Invalid email')
    }
    else{
      
      checkSignup.current = true
    }
      
    if(!checkSignup.current)
      return


    const baseUrl = serverUrl
      
    const request = axios.post(baseUrl + `signup`, {
          'username': subUser,
          'email': subEmail,
          'password': subPassword,
          'remember': remember
        }, { withCredentials:true
        }
      
    )

    request.then(response=>{
      if(response.status === 201){
        window.location.replace('/')
        
      }
      else
        setWarning(`Invalid data format`)

    })
    .catch(function (error) {

      if(error.response === undefined){
        setWarning(`Unable to connect to server`)
        return
      }

      if(error.response.status  === 401){
        if(error.response.data === 'username exists')
          setWarning('That username already exists')
        else
          setWarning('That email already exists')
      }
      else
        setWarning(`Invalid data format`)
    
    })

  }


  return (
    <div style={{margin:'24px'}}>
    
      <div className='centerBox' style={{width:'fit-content'}}>
        
        <h2 className='finishHeader' style={{fontSize:'1.75rem'}}>Sign Up</h2>

        <div className="container">
          <form>
          <label htmlFor="user" className="smallText"><b>Username</b></label><br/>
          <OutlinedInput id='user' className='field' size="small" type="text"  name="user" required onChange={(e)=>changeUser(e.target.value)} /> 
          {warning === ''? <p className = 'gaplessWarning'>&nbsp;</p>:<p className = 'gaplessWarning'>{warning}</p>}

          <label htmlFor="email" className="smallText"><b>Email</b></label><br/>
          <OutlinedInput autoComplete='email' id='email' className='field' size="small" type="email" name="email" required onChange={(e)=>changeEmail(e.target.value)} /> <br/> <br/>

          <label htmlFor="eyePassword" className="smallText"><b>Password</b></label><br/>
          <OutlinedInput
          className='field'
          size="small"
          id="eyePassword"
          type={showPassword? "text"  :  "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={()=>setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword? <VisibilityOff />  : <Visibility /> }
              </IconButton>
            </InputAdornment>
          }
          name="password"
          onChange={(e)=>changePassword(e.target.value)}
        />
        <br/> <br/>
        <label >
            <input type="checkbox"  name="remember" onChange={()=>changeRemember(!remember)}/> &nbsp;
            Remember me </label> 
            <br/> <br/>
          <div className='centerBtn'>
          <button type="submit" className="btn btn-primary buttonFont" onClick={(e)=>submitSignup(e, subUser, subEmail, subPassword)}>Sign up</button> <br/> <br/>

          </div>
         </form>
              <span className ='centerExtra transparent'>Username must be between 2 and 20 characters</span>
            
        </div>
       

      </div>
     
    </div>
  )
}

export default Signup