
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ScoreList from '../components/scoreList';

import { useOutletContext } from 'react-router-dom';

import '../components/flex.css'
import '../components/stats.scss'
import AchievementList from '../components/achievementList';

const Scores =()=>{

    const exportData = useOutletContext()

    

return(
    <div style={{margin:'24px'}}>
        <Tabs
        defaultActiveKey="scores"
        id="justify-tab-example"
        className="mb-3 myTabs"
        transition={false}
        justify
        >
        <Tab eventKey="scores" title="Scores" className='tab1' >
            {exportData.current === null?
            <div >
                Create an account to save your high scores!
            </div>
            :
            exportData.current === 'none'? 
                <div>
                    Play games to save your high scores!
                </div>
            : <ScoreList scoreArray={exportData.current.scores}/>
            }   
            
        </Tab>
        <Tab eventKey="achievements" title="Achievements" className='tab1'>
        {exportData.current === null?
            <div >
                Create an account to earn achievements!
            </div>
            :
            exportData.current === 'none'? 
                <div>
                    Play games to earn achievements!
                </div>
            : <AchievementList achievementArray={exportData.current.achievements}/>
            }   
        </Tab>

        </Tabs>
    </div>
)



}

export default Scores