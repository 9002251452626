import { Navigate, Outlet} from "react-router-dom"

const ProtectNotLogin = ({loaded, loggedIn}) =>{


    return( loaded? 
      loggedIn?  <Navigate to= '/' replace={true}/> :  <Outlet/> // pages only available to logged out users
      : <div> </div>  // return nothing if not loaded yet
    )
  }
  
  export default ProtectNotLogin