
import {Route, Routes, Link, useLocation} from "react-router-dom"
import { useRef, useEffect, useState} from "react"
import Home from "./pages/Home"
import Game from "./pages/Game"
import Signup from "./pages/Signup"
import Login from "./pages/Login"
import Profile from "./pages/Profile"
import ProfileIndex from "./pages/ProfileIndex"
import ProfileEmail from "./pages/ProfileEmail"
import ProfilePassword from "./pages/ProfilePassword"
import NoMatch from "./pages/NoMatch"
import NewPassword from "./pages/NewPassword"
import ResetComplete from "./pages/ResetComplete"
import ProtectReset from "./components/protectReset"
import ProtectNotLogin from "./components/protectNotLogin"


import Stats from "./pages/Stats"
import Scores from "./pages/Scores"
import About from "./pages/About"


import dogText from './files/dog-list.json'
import {useNavigate} from "react-router-dom"
import axios from "axios"


import 'bootstrap/dist/css/bootstrap.css'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'


import DropdownButton from 'react-bootstrap/DropdownButton'


import './components/flex.css'
import './components/home.css'
import './components/fonts.css'


import ChooseGame from "./pages/ChooseGame"

import ProtectedRoute from "./components/protected"

import ResetPassword from "./pages/ResetPassword"



import TopModal from "./components/topModal"

import serverUrl from './config'



const App = () => {

    const location = useLocation()    
   

  const [loggedIn, setLoggedIn] = useState(false)

  const [loaded, setLoaded] = useState(false)



  const [username, setUsername] = useState('')

  const [toggle, setToggle] = useState(false)

  const [show, setOnShow] = useState(false)


  const initialRef = useRef(false)


  const navigate = useNavigate()

  const baseUrl = serverUrl

  if(!initialRef.current){
    initialRef.current = true
    //console.log(Date.now())
  }


  const checkLogin=()=>{

    const request = axios.get(baseUrl, {withCredentials:true})

    request.then(response=>{
      if(response.status === 200){
        setLoggedIn(true)
        setUsername(response.data)

      }
      else{
        setLoggedIn(false)
      }

        setLoaded(true)
    })
    .catch(function (error) {

      setLoggedIn(false)

      setLoaded(true)
    })

  }

  const handleShow=(state)=>{
    setOnShow(state)
  }

  // eslint-disable-next-line
  const handleToggle=()=>{
    setToggle(!toggle)
  }


  const logout =()=>{

    const request = axios.get(baseUrl + 'logout', {withCredentials:true})

    request.then(response=>{
      if(response.status === 200){
        window.location.replace('/')
      }
      else{
        setLoggedIn(false)
        handleShow(true)
      }    
      
    })
    .catch(function (error) {

        setLoggedIn(false)
        handleShow(true)
    })
  }

  useEffect(() => {
    
    checkLogin()
    
    // eslint-disable-next-line
  }, [toggle])


  return (
  
    <div className = "wholeScreen" id = 'wholeScreenId'>
       
       <div className="contentWrapper">
       <div className="header" style={{ }} >

      <Navbar collapseOnSelect expand="md" className='' style={{padding:'0'}}>

     
        <Navbar.Brand as={Link} to='/' className='logo' 
              style={{fontFamily: 'puffy', fontSize: '48px',textDecoration:'none', color: '#0d6efd', padding:'0'}}>

                Dog&nbsp;Shuffle</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse > 
          
          <Nav className='ms-auto verticalText' >
            
             
          <Nav.Link style={{padding:'0 24px 0px 0', height:'fit-content', textAlign:'left', width: '100%'}} 
          eventKey={1} as={Link} to="/" className=" navMenu ">Home</Nav.Link>

            <Nav.Link style={{padding:'0 24px 0px 0', height:'fit-content', textAlign:'left', width:'100%'}} 
            eventKey={2} as={Link} to="/stats" className="navMenu">Stats</Nav.Link>

<Nav.Link style={{padding:'0 24px 0px 0', height:'fit-content', textAlign:'left', width:'100%'}} 
            eventKey={3} as={Link} to="/about" className="navMenu">About</Nav.Link>
              
            
          
           <div className="header2 " >
              {loaded?
              loggedIn?
              <DropdownButton  title={`Welcome ${username}`} style={{marginTop:'8px', marginBottom:'8px'}}>
                <NavDropdown.Item eventKey={3} className='dropdownItem' onClick={()=>navigate('/profile')}>Profile</NavDropdown.Item>
                <NavDropdown.Item eventKey={4} className='dropdownItem' onClick={logout}>Log&nbsp;out</NavDropdown.Item>
              </DropdownButton>
              : <button className = "loginHeader btn btn-primary" style={{width:'100%'}}
              onClick={()=> navigate("/login")}>Log&nbsp;in</button>
              
              : <div> </div> // return empty while server is checking for cookie
              }
              </div>
             
              </Nav>

          
        </Navbar.Collapse>
     
    </Navbar>

      </div>

      <TopModal show={show} handleShow={handleShow}/>

      

  <Routes>
        <Route path="/" element={<Home dogs = {dogText}/>} />
        <Route path="/game" element={<ChooseGame />} />

        <Route element = {<ProtectedRoute />}>
            <Route element = {<Game />} path = "/game/play" /> 
        </Route>

        <Route element = {<ProtectNotLogin loaded={loaded} loggedIn={loggedIn}/>}>
          <Route path="/login" element={<Login/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>

        
        <Route path="/new-password" element={<NewPassword />} />

        <Route element = {<ProtectReset />}>
            <Route element = {<ResetComplete />} path = "/reset-complete" /> 
        </Route>

        <Route path="/profile" element={<Profile />} >
            <Route index element={<ProfileIndex />} />
            <Route path="change-email" element={<ProfileEmail />} />
            <Route path="change-password" element={<ProfilePassword />} />
            <Route path="*" element={<NoMatch />} />
        </Route>

        <Route path="/stats" element={<Stats />} >
            <Route index element={<Scores />} />
            <Route path="*" element={<NoMatch />} />
        </Route>

        <Route path="/about" element={<About />} />
        
  
        <Route path="*" element={<NoMatch />} />
  </Routes>

  </div>

 
  {
    location.pathname === '/game/play'?
    <div></div> // don't show footer during game
    :
    <footer id="footer"> 
      <div style={{display:'flex', justifyContent:'flex-start', columnGap:'15px', flexWrap:'wrap'}}>
        <p className="footerText" >
          Anthony@speedrunyourknowledge.com</p>

        <a rel="noreferrer" href='https://www.youtube.com/@speedrunyourknowledge' target='_blank'>
          <div title='youtube channel' className="youtubeBox"> </div>
          </a>

        <a rel="noreferrer" href='https://twitter.com/SpeedrunyourK' target='_blank'>
        <div title='twitter' className="twitterBox"> </div>
        </a>
      </div>
    
    
    </footer>
  }  

   
    </div>
   
  )
}

export default App

