
import '../components/flex.css'


const NoMatch =()=>{


    return(
        <div>
            <h1 className="notFound">404 Page Not Found</h1>
        </div>
    )
}

export default NoMatch