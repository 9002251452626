import { useNavigate } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css'

import '../components/flex.css'
import '../components/login.css'



const ResetComplete =()=>{

    const navigate = useNavigate()

    return(

    <div style={{margin:'24px'}}>
        <div className="centerBox centerText">
              <h2 className="finishHeader">Reset Complete</h2>
              <p>Your password has been reset</p>
                <br/>
              <button className="btn btn-primary buttonFont fitContent" 
              onClick={()=>navigate('/login')}>Go to Login Page</button>


        </div>
    </div>

    )

}

export default ResetComplete