export function filterItems(items, query) {
    query = query.toLowerCase().trim();
    
    return items.filter(item =>
      item.name.toLowerCase().includes(query)
      
    )
   }

export function capitalizeWords(words) {
  return words.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ')
}

export function chooseDogs(dogs, numQuestions) {
  const getRnd = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) ) + min
  }

 // console.log(dogs)

  let questionList = []

  let remainingDogs = dogs.slice(0)

  let finalThree
  

  for(let i = 0; i < numQuestions; i++){
    const index = getRnd(0, remainingDogs.length - 1)

    const correctAnswer = remainingDogs[index].name

    const questionQuery = remainingDogs[index].query

    let questionChoices = []

    questionChoices = questionChoices.concat(correctAnswer)

      // there are 3 dogs left including this one
      if(remainingDogs.length === 3){

        // exclude last 3 dogs from possible choices
        finalThree = dogs.filter((dog)=> !remainingDogs.find(({id})=> dog.id === id))
      } 


    remainingDogs.splice(index, 1)

    let remainingChoices

      if(remainingDogs.length <= 2){
        remainingChoices = finalThree.slice()
      }
      else{
        remainingChoices = remainingDogs.slice()
      }


    for(let j = 0; j < 3; j++){

      const newIndex = getRnd(0, remainingChoices.length - 1)

      const choice = remainingChoices[newIndex].name

      questionChoices = questionChoices.concat(choice)

      remainingChoices.splice(newIndex, 1)
    }

    const question = {
      answerIndex: 0,
      answer: correctAnswer,
      choices: questionChoices,
      query: questionQuery
    }

    questionList = questionList.concat(question)
  }

  return questionList
}