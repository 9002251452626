
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './home.css'

function TopModal({show, handleShow}) {

  const handleClose = () => handleShow(false);

  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        
      >
        <Modal.Header closeButton >
          <Modal.Title  >You are not logged in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your cookies may have expired. Try logging in again.
          
        </Modal.Body>
        <Modal.Footer style={{
          display: "flex",
          justifyContent: "center",
        }}>

          <Button variant="primary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TopModal;