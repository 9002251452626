
// change the env here
const env = 'website'

const serverUrl = {

    website: 'https://api.dogshuffle.speedrunyourknowledge.com/',
    local: 'http://localhost:3001/'
}


export default serverUrl[env]