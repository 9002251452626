import { useState} from 'react'
import { filterItems } from '../components/data'
import {useNavigate} from "react-router-dom"

import 'bootstrap/dist/css/bootstrap.css'


import '../components/flex.css'
import '../components/home.css'



const Home = ({dogs}) => {

  const navigate = useNavigate()
  
  const [query, setQuery] = useState('')

  const handleFilterChange = (e) =>{
    setQuery(e.target.value);
  }

  const testList = filterItems(dogs, query)


  return (
   
    <div className='homeFlex' style={{margin:'24px'}}>

      <div style={{display:'flex', flexDirection:'column'}}>

          <div style={{marginRight:'24px', display:'flex', flexDirection:'column', flex:'1 1 0'}} >
            <h2>List of Dogs</h2>
            <div id='showDog' >
                Show dogs with: <input  value = {query} onChange={handleFilterChange}/>
            </div>
          
              <ul className= 'dogList' style = {{listStyleType: "none", overflow: 'auto',  minHeight:'20rem', flex:'1 1 0', marginBottom:'0'}}  >
                {testList.map(dog => 
                  <li key={dog.id}> {dog.name} </li>
                )}
              </ul>
          </div>

      </div>

      <div className='homeSummary' style={{display:'flex', flexDirection:'column', minHeight:'0'}}>
      <button className = "homeButton playHome btn btn-primary" onClick={()=> navigate("/game")}>Play Game</button>
        <h2>Welcome to Dog Shuffle!</h2>
        <p style={{marginBottom:'1.75rem'}}>Dog Shuffle is a randomized quiz on 145 types of dogs. 
          The questions are different every time you play! 
          Challenge your friends and see who is the better dog detective!</p>
        <h2>Want to learn more?</h2>
        <p>I have collected videos of the coolest animals in the world and 
          put them all together in the <a rel="noreferrer" href='https://speedrunyourknowledge.com/animal-taxonomy/' target="_blank">ultimate guide</a> to taxonomy {`(the science of classification)`}.

        </p>
        <p>If you want to learn about the strangest insects in the world, check out 
          the <a rel="noreferrer" href='https://speedrunyourknowledge.com/fascinating-insects-part-2/' target="_blank">ultimate guide</a> to entomology {`(the science of insects)`}. 
        </p>
      </div>

         
      </div>
   
    
  )
}

export default Home