
import { useRef, useState} from "react"

import 'bootstrap/dist/css/bootstrap.css'

import OutlinedInput from "@mui/material/OutlinedInput"

import '../components/flex.css'
import '../components/login.css'

import axios from 'axios'
import serverUrl from '../config'

const ResetPassword = () => {

    const checkSignup = useRef(true)

    const [subEmail, changeEmail] = useState('')
    const [warning, setWarning] = useState('')

    const [enter, setEnter] = useState(false)


    const submitSignup =(e, subEmail)=>{

        e.preventDefault()

        const regexEmail = /^[A-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[A-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[A-Z0-9-]+(?:\.[A-Z0-9-]+)*$/i

        if(subEmail === ''){
        setWarning('An email is required')
        checkSignup.current = false
        }
        else if(subEmail.length > 256){
        setWarning('Email cannot exceed 256 characters')
        checkSignup.current = false
        } 
        else if(!regexEmail.test(subEmail)){
        setWarning('Please enter a valid email')
        checkSignup.current = false
        }
        else{
          checkSignup.current = true
        }

        if(!checkSignup.current)
            return

        const baseUrl = serverUrl
      
        const request = axios.post(baseUrl + `reset`, {

            'email': subEmail,
            }, { withCredentials:true
            }
      
        )
        

        // show email as 'sent' unless internet connection fails
        request.then(response=>{
            setEnter(true)

          })
          .catch(function (error) {

            if(error.response === undefined){
              setWarning(`Unable to connect to server`)
              return
            }

            setEnter(true)

          })
    }     
  
  
    return ( 
      <div style={{margin:'24px'}}>
        <div className="centerBox" >


          {enter? <div> <h2 className="finishHeader">Email Sent</h2>
                <p>A password reset link has been sent to <b>{subEmail}</b> if this email belongs to an account. 
                 Be sure to check your spam or junk mail folder.</p> 
                <p>This link will expire in 1 hour.</p>
            </div>
            : <div> <h2 className="finishHeader">Enter your Email</h2>
            <div style={{margin:'auto', width:'fit-content'}} >
              <p style={{textAlign:'left'}}>Enter your email and a password reset link will be sent to you</p>
            </div>
            <form className="centerDiv">
                <label htmlFor="email" className="smallText "><b>Email</b></label><br/>
            <div >
          <OutlinedInput autoComplete="email" id='email' className='field' size="small" type="email" name="email" required 
                onChange={(e)=>changeEmail(e.target.value)} /> 
                {warning === ''? <p className = 'gaplessWarning'>&nbsp;</p>:<p className = 'gaplessWarning'>{warning}</p>}
                 <button type="submit" className="btn btn-primary buttonFont" 
                 onClick={(e)=>submitSignup(e, subEmail)}>Submit</button> <br/> <br/>
            </div>
            
            <span className ='centerExtra transparent'>Don't have an account? Sign up now</span>
            </form>
                
                
             </div>
          }

        </div>
  
      </div>
    )
  }
  
  export default ResetPassword