import { Navigate, Outlet, useLocation} from "react-router-dom"

const ProtectReset = () =>{

    const location = useLocation()

    let enabled

    if(location.state === undefined || location.state === null)
        enabled = false
    else if(location.state.reset === undefined || location.state.reset === null)
        enabled = false
    else
        enabled = true


    return( enabled?  <Outlet/> : <Navigate to= '/'/>
    )
  }
  
  export default ProtectReset