import { useRef, useState} from "react"
import { useSearchParams, useNavigate } from "react-router-dom"

import 'bootstrap/dist/css/bootstrap.css'

import IconButton from "@mui/material/IconButton"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

import '../components/flex.css'
import '../components/login.css'
import serverUrl from '../config'

import axios from 'axios'

const NewPassword =()=>{

    const navigate = useNavigate()

    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const userId = searchParams.get("userid")
    const requestId = searchParams.get("requestid")

  

    const [warning, setWarning] =useState('')
    const [showPassword, setShowPassword] = useState(false)

    const [subPassword, changeSubPassword] = useState('')
   

    const checkSignup = useRef(true)


    const submitPassword=(userId, requestId, subPassword)=>{

        if(userId === null || requestId === null){
            setWarning('Your password reset link is invalid or has expired')
            return
        }

        if(isNaN(parseInt(userId))){
            setWarning('Your password reset link is invalid or has expired')
            return
        }

        const regexPassword = /^[^\s]+$/
        // password
      if(subPassword === ''){
        setWarning('A password is required')
        checkSignup.current = false
      }   
      else if(subPassword.length > 256){
        setWarning('Password cannot exceed 256 characters')
        checkSignup.current = false
      }
      else if(subPassword.length < 8 ){
        setWarning('Password must be at least 8 characters')
        checkSignup.current = false
      }
      else if(!regexPassword.test(subPassword) ){
        setWarning('Password cannot include spaces')
        checkSignup.current = false
      }
      else{
        checkSignup.current = true
      }

      if(!checkSignup.current)
        return


      const baseUrl = serverUrl + 'reset/'
      
      const request = axios.post(baseUrl + `new-password`, {
            'userId': parseInt(userId),
            'requestId': requestId,
            'password': subPassword
  
          }, { withCredentials:true
          }
        
      )
  
      request.then(response=>{
        if(response.status === 201){
            navigate('/reset-complete', { state: { reset:true} })
        }
        else
          setWarning(`Invalid data format`)
  
      })
      .catch(function (error) {

        if(error.response === undefined){
          setWarning(`Unable to connect to server`)
          return
        }
  
      if(error.response.status  === 401 && error.response.data === 'invalid url'){

          setWarning('Your password reset link is invalid or has expired')
      }
      else
        setWarning(`Invalid data format`)
      
      })


    }



    return(

    <div style={{margin:'24px'}}>
        <div className="centerBox" style={{width:'fit-content'}}>
              <h2 className="finishHeader">Enter Your New Password</h2>
     
    <label htmlFor="eyePassword1" className="smallText"><b>New Password</b></label><br/>
    <OutlinedInput 
        className='field clearBackground'
        size="small"
        id="eyePassword1"
        value={subPassword}
        type={showPassword? "text"  :  "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={()=>setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword? <VisibilityOff />  : <Visibility /> }
            </IconButton>
          </InputAdornment>
        }
        name="password"
        onChange={(e)=>changeSubPassword(e.target.value)}
      />
      {warning === ''? <p className = 'gaplessWarning'>&nbsp;</p>:<p className = 'gaplessWarning'>{warning}</p>}

      <button type="submit" className="btn btn-primary buttonFont" onClick={()=>submitPassword(userId, requestId, subPassword)}>Submit</button><br/>
        <span className ='centerExtra transparent'>Your password reset link is invalid or has expired</span>
        </div>
    </div>

    )

}

export default NewPassword