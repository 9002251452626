import { useEffect, useState, useRef} from "react"

const Timer = ({limit, endFunction, index, stopTime}) =>{

    const [seconds, setSeconds] = useState(limit)

    const countRef = useRef(null)
    countRef.current = seconds


    useEffect(() => {

        if(!stopTime)
            setSeconds(limit)

        const interval = setInterval(() => {

            if(!stopTime){
                setSeconds(seconds => seconds - 1)

                // stop timer as it changes to 0
                if(countRef.current === 1){
                    
                    clearInterval(interval)
                    endFunction() // goes to next question
                }
            }
        }, 1000)
    
        return () => clearInterval(interval)
        // eslint-disable-next-line
      }, [index, stopTime]);


    return(
        <div className="timer">
            {seconds} seconds
        </div>
    )

}

export default Timer