
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './home.css'

function CookieModal({show, handleShow}) {

  const handleClose = () => handleShow(false);

  return (
    <>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        
      >
        <Modal.Header closeButton >
          <Modal.Title  >Cookies are disabled</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Cookies are used to maintain your login session.
          This website only uses necessary cookies for things like page navigation 
          and saving data to your account. You need to enable cookies to log in. 
          If you do not wish to enable cookies, you can continue to play signed out.
          
        </Modal.Body>
        <Modal.Footer style={{
          display: "flex",
          justifyContent: "center",
        }}>
          
          <Button variant="primary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CookieModal;