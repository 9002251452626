import { useState, useRef} from 'react'
import { Link} from "react-router-dom"

import 'bootstrap/dist/css/bootstrap.css'

import '../components/flex.css'
import '../components/login.css'

import axios from 'axios'

import IconButton from "@mui/material/IconButton"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

import CookieModal from "../components/cookieModal"
import serverUrl from '../config'


const Login = () => {


  const [showPassword, setShowPassword] = useState(false)

  const [subUser, changeUser] = useState('')
  const [subPassword, changePassword] = useState('')

  const [remember, changeRemember] = useState(false)

  const [warning, setWarning] = useState('')

  const [cookieWarning, setCookieWarning] = useState(false)

  const checkLogin = useRef(true)

  const handleCookie=(state)=>{
    setCookieWarning(state)
  }

  const submitLogin =(e, subUser, subPassword)=>{

    e.preventDefault()

    const regexEmail = /^[A-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[A-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[A-Z0-9-]+(?:\.[A-Z0-9-]+)*$/i

    const regexUser = /^[a-zA-Z0-9'_-]+$/

    const wantEmail = /^.*[@]+.*$/

    const regexPassword = /^[^\s]+$/

    let choose = ''

    // username
    if(subUser === ''){
      setWarning('A username or email is required')
      checkLogin.current = false
      return
    } 

    if(!wantEmail.test(subUser)){
        choose = 'user'

        if(subUser.length < 2 || subUser.length > 20){
            setWarning('Usernames are between 2 and 20 characters')
            checkLogin.current = false
            return
          }
        else if(!regexUser.test(subUser)){
          setWarning(`Valid username characters are A-Z 0-9 '_-`)
            checkLogin.current = false
            return
        }
    }
    else{
    // email
        choose = 'email'

        if(subUser.length > 256){
            setWarning('Emails cannot exceed 256 characters')
            checkLogin.current = false
            return
        } 
        else if(!regexEmail.test(subUser)){
            setWarning('Please enter a valid email')
            checkLogin.current = false
            return
        }
    }


    // password
    if(subPassword === ''){
      setWarning('A password is required')
      checkLogin.current = false
    }   
    else if(subPassword.length > 256){
      setWarning('Passwords cannot exceed 256 characters')
      checkLogin.current = false
    }
    else if(subPassword.length < 8 ){
      setWarning('Passwords must be at least 8 characters')
      checkLogin.current = false
    }
    else if(!regexPassword.test(subPassword) ){
      setWarning('Password cannot include spaces')
      checkLogin.current = false
    }
    else{
      
      checkLogin.current = true
    }
      
    if(!checkLogin.current)
      return

    
    if (!navigator.cookieEnabled){
      setWarning(`You need to enable cookies to log in`)
      handleCookie(true)
      return
    }
    

    const baseUrl = serverUrl
    
    let request

    if(choose === 'user'){
        request = axios.post(baseUrl + `login`, 
            {
                'username': subUser,
                'password': subPassword,
                'remember': remember
            }, { withCredentials:true
                }
        )
    }
    else{
        request = axios.post(baseUrl + `login`, 
            {
                'email': subUser,
                'password': subPassword,
                'remember':remember
            }, { withCredentials:true
                }
        )
    }




    request.then(response=>{
      if(response.status === 200){
        window.location.replace('/')
        
      }
      else
        setWarning(`Invalid data format`)

    })
    .catch(function (error) {

      if(error.response === undefined){
        setWarning(`Unable to connect to server`)
        return
      }
      

      if(error.response.status  === 401 && choose === 'user'){
        setWarning(`Your username or password is incorrect`)
      }
      else if(error.response.status  === 401 && choose === 'email'){
        setWarning(`Your email or password is incorrect`)
      }
      else if(error.response.status  === 401 && error.response.data === 'not enabled'){
        setWarning(`You need to enable cookies to log in`)
        handleCookie(true)
      }
      else{
        setWarning(`Invalid data format`)
      }
  
    })

  }


  return (
    <div style={{margin:'24px'}}>
      
      <div className='centerBox' style={{width:'fit-content'}}>
        
        <h2 className='finishHeader' style={{fontSize:'1.75rem'}}>Log In</h2>

        <div className="container">
            <p className ='centerExtra'>Don't have an account? &nbsp; 
                <Link to='/signup'>Sign up now</Link> 
            </p>
          <form className='formStyle'>
          <label className="smallText" htmlFor="user"><b>Username or Email</b></label>
          <OutlinedInput id='user' className='field' size="small" type="text"  name="user" onChange={(e)=>changeUser(e.target.value)} /> 
          
          {warning === ''? <p className = 'gaplessWarning'>&nbsp;</p>:<p className = 'gaplessWarning'>{warning}</p>}
          
          

          <label htmlFor="eyePassword" className="smallText"><b>Password</b></label><br/>
          <OutlinedInput
          className='field'
          size="small"
          id="eyePassword"
          type={showPassword? "text"  :  "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={()=>setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword?  <VisibilityOff />  : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          name="password"
          onChange={(e)=>changePassword(e.target.value)}
        />

         <br/> <br/>
         <div style={{display:'flex', justifyContent:'space-between'}}>
            <label className='topText'>
              <input type="checkbox"   name="remember" onChange={()=>changeRemember(!remember)}/> &nbsp;
                  Remember me&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </label>

            <Link className="smallText" to='/reset-password'>Forgot password?</Link> 
          </div>
                <br/>
            <div className='centerBtn'>
                <button type="submit" className="btn btn-primary buttonFont" onClick={(e)=>submitLogin(e, subUser, subPassword)}>Log in</button> <br/> <br/>
            </div>
          
            </form>
            <span className ='centerExtra transparent'>Valid username characters are A-Z 0-9 ._-</span>
        </div>

      </div>

      <CookieModal show={cookieWarning} handleShow={handleCookie}/>
    </div>
  )
}

export default Login