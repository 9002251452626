import { useState,  useEffect, useRef} from 'react'
import { Outlet } from 'react-router-dom'


import axios from 'axios'
import serverUrl from '../config'

const Stats =()=>{


    const [loaded, setLoaded] = useState(false)

    const exportData = useRef(null) // if this remains null, that means user is not logged in

    const getStats =()=>{
        const baseUrl = serverUrl + 'game/'
            
        const request = axios.get(baseUrl + 'stats', { withCredentials:true})
            

        request.then(response=>{
            if(response.status === 200){

                exportData.current = response.data
            }

            setLoaded(true) // done getting data
        })
        .catch(function (error) {
            
            setLoaded(true) 
        })
    }


    useEffect(() => {

    getStats()

    // eslint-disable-next-line
    }, [])


    return ( loaded? <Outlet context={exportData}/> : <div> </div> // show nothing if not loaded

    )
}

export default Stats