import { useState,  useEffect, useRef} from 'react'
import { Outlet } from 'react-router-dom'

import axios from 'axios'
import serverUrl from '../config'

const Profile =()=>{

    

    const [loaded, setLoaded] = useState(false)

    const exportData = useRef(null)

    const getProfile =()=>{
        const baseUrl = serverUrl
            
        const request = axios.get(baseUrl + 'profile', { withCredentials:true})
            

        request.then(response=>{
            if(response.status === 200){

                exportData.current = response.data

                if(exportData.current.dog === null)
                    exportData.current.dog = 'none'
          
                setLoaded(true) // load profile information
            }
            else{
            window.location.replace('/login') //redirect to login page
            }
        })
        .catch(function (error) {
            window.location.replace('/login')
        })
    }


    useEffect(() => {

    getProfile()

    // eslint-disable-next-line
    }, [])


    return ( loaded? <Outlet context={exportData}/> : <div> </div> // show nothing if not loaded

    )
}

export default Profile