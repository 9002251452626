
import {useNavigate} from "react-router-dom"
import { useState } from "react"

import '../components/choose.css'
import '../components/home.css'
import '../components/flex.css'




const ChooseGame = () => {

  const navigate = useNavigate()

  const [quantity, setQuantity] = useState(10)


  const changeNumber=(e)=>{

    const number = parseInt(e.target.value)

    setQuantity(number)
  }

  
  

  return (
    
        <div className="flexChoose localChoose " >
         
          <div>
            <div className="expand">
                <button className = "chooseButton green "onClick={()=> { navigate("/game/play", 
                { state: { mode: 1, number: quantity} })}}>Easy</button>
            </div>

            <div className="expand">
                <button className = "chooseButton blue "onClick={()=> { navigate("/game/play", 
                { state: { mode: 2, number: quantity} })}}>Normal</button>
            </div>

            <div className="expand">
                <button className = "chooseButton red "onClick={()=> { navigate("/game/play", 
                { state: { mode: 3, number: quantity} })}}>Hard</button>
            </div>

            <div>
                <button className = "chooseButton gold specialButton sheen"onClick={()=> { navigate("/game/play", 
                { state: { mode: 4, number: 145} })}}>All&nbsp;Dog&nbsp;Mode</button>
            </div>
          </div>

           
            <div className='questionMenu '>
                
                <p className="textLeft">Number&nbsp;of Questions</p>
                
                <label htmlFor="ten" className = 'questionLabel'>
                <input type="radio" id="ten" name="quantity" value = '10' onChange = {changeNumber}/>
                &nbsp;&nbsp;<span className="radioText">10</span>
                </label>
               
                
                <label htmlFor="twenty" className = 'questionLabel'>
                <input type="radio" id="twenty" name="quantity" value = '20' onChange = {changeNumber}/>
                &nbsp;&nbsp;<span className="radioText">20</span>
                </label>
               
                
                <label htmlFor="thirty" className = 'questionLabel'>
                <input type="radio" id="thirty" name="quantity" value = '30' onChange = {changeNumber}/>
                &nbsp;&nbsp;<span className="radioText">30</span>
                </label>
               
            </div>
            
        </div>
      
    
  )
}

export default ChooseGame