import { useState, useRef} from 'react'
import { Link } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'

import IconButton from "@mui/material/IconButton"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"

import '../components/flex.css'
import '../components/login.css'

import axios from 'axios'
import serverUrl from '../config'

const ProfilePassword = () => {


  const [showPassword, setShowPassword] = useState(false)

  const [newPassword, changeNewPassword] = useState('')
  const [currentPassword, changeCurrentPassword] = useState('')


  const [warning, setWarning] = useState('')
  const correct = useRef(false)


  const checkSignup = useRef(true)

  const submitPassword=(newPassword, currentPassword)=>{

    correct.current = false

    const regexPassword = /^[^\s]+$/

    // current password
    if(currentPassword === ''){
        setWarning('You have not entered a current password')
        checkSignup.current = false
    }
    else if(currentPassword.length > 256){
        setWarning('Password cannot exceed 256 characters')
        checkSignup.current = false
      }
    else if(currentPassword.length < 8 ){
        setWarning('Password must be at least 8 characters')
        checkSignup.current = false
    }
    else if(!regexPassword.test(currentPassword) ){
        setWarning('Password cannot include spaces')
        checkSignup.current = false
      }
    // new password
    else if(newPassword === ''){
        setWarning('You have not entered a new password')
        checkSignup.current = false
    }
    else if(newPassword.length > 256){
        setWarning('Password cannot exceed 256 characters')
        checkSignup.current = false
      }
    else if(newPassword.length < 8 ){
        setWarning('Password must be at least 8 characters')
        checkSignup.current = false
    }
    else if(!regexPassword.test(newPassword) ){
        setWarning('Password cannot include spaces')
        checkSignup.current = false
      }
    else if(newPassword === currentPassword){
        setWarning('New password is the same as the current one')
        checkSignup.current = false
    }
    else
        checkSignup.current = true


    if(!checkSignup.current)
        return


    const baseUrl = serverUrl + 'profile/'
      
    const request = axios.post(baseUrl + `change-password`, {
          'currentPassword': currentPassword,
          'newPassword': newPassword

        }, { withCredentials:true
        }
      
    )

    request.then(response=>{
      if(response.status === 201){
        setWarning('Your password has been updated')

        changeCurrentPassword('')
        changeNewPassword('')
        correct.current = true
      }
      else
        setWarning(`Invalid data format`)

    })
    .catch(function (error) {

      if(error.response === undefined){
        setWarning(`Unable to connect to server`)
        return
      }

        if(error.response.status  === 401 && error.response.data === 'wrong password')
            setWarning('Your current password is incorrect')
        else
            setWarning(`Invalid data format`) 
    
    })

    }



  return (
    <div className="flexMenu">
          <div className=" container-fluid " style={{paddingRight:'0', paddingLeft:'0'}}>
    <div className="row justifyFlex" style={{marginLeft:'0', marginRight:'0', columnGap:'16px'}} >
        <div className="col-md-3 px-md-2 px-0  d-flex sticky-top" >
            <div id='menuParent' className="d-flex flex-md-column flex-row flex-grow-1 align-items-center align-items-md-start px-3  text-white" >

                <ul className=" nav nav-pills flex-md-column flex-row flex-nowrap flex-shrink-1 flex-md-grow-0 flex-grow-1 mb-md-auto mb-0 justify-content-center align-items-center align-items-md-start" id="menu" >
                    <li className='nav-item'>
                        <Link to='/profile' style={{textDecoration:'none', fontSize:'1.25rem', marginTop:'20px'}} className="nav-link px-md-0 px-0">
                        <span className="ms-1 d-none d-md-inline blackLink">Profile</span>
                        </Link>
                    </li>
                    <li>
                    <Link to='/profile/change-email' style={{textDecoration:'none', fontSize:'1.25rem'}} className="nav-link px-md-0 px-0">
                        <span className="ms-1 d-none d-md-inline blackLink">Change&nbsp;Email</span>
                        </Link>
                    </li>
                    <li>
                    <Link to='/profile/change-password' style={{textDecoration:'none', fontSize:'1.25rem'}} className="nav-link px-md-0 px-0">
                        <span  className="ms-1 d-none d-md-inline blackLink">Change&nbsp;Password</span>
                        </Link>
                    </li>
                    <li  className="dropdown waitSmall " >
                        <button className=" normalButton nav-link dropdown-toggle px-md-0 px-3" id="dropdown" data-bs-toggle="dropdown" 
                        aria-expanded="false" >
                            <span className="fs-5 bi-bootstrap" >View&nbsp;Profile</span>
                        </button>
                        <ul className=" dropdown-menu  text-small shadow " aria-labelledby="dropdown" >
                            <li><Link to='/profile' className="dropdown-item" >Profile</Link></li>
                            <li><Link to='/profile/change-email' className="dropdown-item" >Change&nbsp;Email</Link></li>
                            <li><Link to='/profile/change-password' className="dropdown-item" >Change&nbsp;Password</Link></li>
                  
                           
                        </ul>
                    </li>
                   
                </ul>

            </div>
        </div>
        <div className=" col "style={{padding:'0', minWidth:'0'}} >
            <main className="row overflow-auto" style={{margin:'0'}}>
        

        <div className='rightMenu profileColumn' >

              <div className="center">
              <h2>Change Your Password</h2>
              <p>Enter your current password and your new password</p>
                

              <label htmlFor='eyePassword1' className="smallText"><b>Current Password</b></label><br/>
              <OutlinedInput 
              className='field clearBackground'
              size="small"
              id="eyePassword1"
              value={currentPassword}
              type={showPassword? "text"  :  "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={()=>setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword? <VisibilityOff />  : <Visibility /> }
                  </IconButton>
                </InputAdornment>
              }
              name="password"
              onChange={(e)=>changeCurrentPassword(e.target.value)}
              />
              
              <br/><br/>


              <label htmlFor='eyePassword2' className="smallText"><b>New Password</b></label><br/>
              <OutlinedInput
              className='field clearBackground'
              size="small"
              id="eyePassword2"
              type={showPassword? "text"  :  "password"}
              value={newPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={()=>setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword? <VisibilityOff />  : <Visibility /> }
                  </IconButton>
                </InputAdornment>
              }
              name="password"
              onChange={(e)=>changeNewPassword(e.target.value)}
              />

              {warning === ''? <p className = 'gaplessWarning'>&nbsp;</p>
              :correct.current?
              <p className = 'gaplessWarning correct' >{warning}</p>
              :<p className = 'gaplessWarning' >{warning}</p>}  

              <button type="submit" className="btn btn-primary buttonFont" onClick={()=>submitPassword(newPassword, currentPassword)}>Submit</button> 


              </div>
          </div>
           
            </main>

        </div>
    </div>
</div>




        
    
      
    </div>
  )
}

export default ProfilePassword