

const ScoreList =({scoreArray})=>{



return(
    <div style = {{marginTop: '16px'}} className="flexScores">
        <div style = {{alignSelf: 'flex-start'}}>
            <b className="scoreHeading">Easy</b>
            <ul style = {{listStyleType: "none", paddingLeft: "0", marginBottom:'1.5rem'}}>
            {scoreArray.filter((game)=> game.mode_id === 1).map((game, index) => 
                
                <li className='scoreList' key={index}> {game.num_correct} / {game.num_questions}</li>
                
            )}
            </ul>
        
             
       
        <b className="scoreHeading">Normal</b>
            <ul style = {{listStyleType: "none", paddingLeft: "0", marginBottom:'1.5rem'}}>
            {scoreArray.filter((game)=> game.mode_id === 2).map((game, index) => 

                <li className='scoreList' key={index}> {game.num_correct} / {game.num_questions}</li>
                
            )}
            </ul>
        </div>

        <div >
        <b className="scoreHeading">Hard</b>
            <ul style = {{listStyleType: "none", paddingLeft: "0", marginBottom:'1.5rem'}}>
            {scoreArray.filter((game)=> game.mode_id === 3).map((game, index) => 
                
                <li className='scoreList' key={index}> {game.num_correct} / {game.num_questions}</li>
                
            )}
            </ul>
       

        
        <b className="scoreHeading">All Dog</b>
            <ul style = {{listStyleType: "none", paddingLeft: "0", marginBottom:'1.5rem'}}>
            {scoreArray.filter((game)=> game.mode_id === 4).map((game, index) => 
                
                <li className='scoreList' key={index}> {game.num_correct} / {game.num_questions}</li>
                
            )}
            </ul>
        </div>
        <div></div>
    </div>
 )
}

export default ScoreList