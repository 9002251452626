


const About =()=>{

    return(
        <div style={{margin:'24px'}}>
            <h2 style={{textAlign:'center', fontSize:'1.75rem'}}>About Us</h2>
        <p>My name is Anthony. I made Dog Shuffle using React, Node, and Postgres. 
            Follow me on <a rel="noreferrer" href='https://www.youtube.com/@speedrunyourknowledge' target='_blank'>
            YouTube</a> to see how I make coding fun. My goal is to teach programming 
            through entertainment.
             </p>

        <p>All the dog pictures are retrieved from <a rel="noreferrer" 
        href='https://dog.ceo/dog-api/' target="_blank">Dog API.</a> Dog API collected about 
        20,000 of their photos from the <a rel="noreferrer" 
        href='http://vision.stanford.edu/aditya86/ImageNetDogs/' target="_blank">Stanford Dogs dataset.</a> The purpose of the 
        Stanford Dogs dataset was to train an AI to identify dog breeds. You can submit photos of 
        your own dog to <a rel="noreferrer" 
        href='https://dog.ceo/dog-api/' target="_blank">Dog API.</a> Your photos may even appear in Dog Shuffle! 
        It would be amazing to see more cool dogs from around the world. </p>
        </div>
    )
}

export default About