import './game-screen.css'
import axios from 'axios'
import { useEffect, useState, useRef} from "react"
import Timer from './timer'
import serverUrl from '../config'

const ChoiceGrid = ({questionList, numQuestions, gameMode}) =>{

    const [index, setIndex] = useState(0) 
    const [imageReady, setReady] = useState(false) 
    const [done, setDone] = useState(false) 
    const [chosen, setChosen] = useState("none") 
    const [score, setScore] = useState(0)

    const [points, setPoints] = useState(0)
    
    const [stopTime, setStop] = useState(false)

    const [endGame, setEndGame] = useState(false)

    const [highScore, setHighScore] = useState(false)

    const [loggedIn, setLoggedIn] = useState(false)

    const [failure, setFailure] = useState(false)

    const [orange, setOrange] = useState(false)

    
    const endQuiz = useRef(false)

    const newPoints = useRef(0)
  //  const start = useRef(Math.floor(performance.now()))

    const images = useRef(Array(questionList.length))

    const initialRef = useRef(false)

    const haveImages = useRef(0)

    const finalPoints = useRef(0)
    const finalScore = useRef(0)

    const modeName = useRef(null)
    
    if(gameMode === 1)
        modeName.current = 'Easy'
    else if(gameMode === 2)
        modeName.current = 'Normal'
    else if(gameMode === 3)
        modeName.current = 'Hard'
    else    
        modeName.current = 'All Dog'

    
    const scrollToBottom =()=>{
        const thisContainer = document.getElementById('wholeScreenId')
        const height = thisContainer.scrollHeight * 2
        
        window.scroll(0, height)
        
    }


    const nextImage =()=>{
        if(index < questionList.length - 1){
            setIndex(index + 1)

            setStop(false) // reset timer for next question

            setDone(false)
            setChosen("none")
            setOrange(false)
        }
        else{
            
            endQuiz.current = true
            writeScore(finalPoints.current, finalScore.current)
        }  

    }

   


    const getImage = (index)=>{
        const query = questionList[index].query
  
          const baseUrl = 'https://dog.ceo/api/'
      
          const request = axios.get(baseUrl + `breed/${query}/images/random`)

          request.then(response=>{
            
            let link = response.data.message
            const image1 = new Image()
            image1.src = link

            image1.onload = ()=>{
                images.current[index] = link
                haveImages.current += 1

                if(haveImages.current === questionList.length){

                    setReady(true)
                }
            }
            image1.onerror =()=>{
                getImage(index)
                
            }
          }).catch((error)=>{
           
            setFailure(true)
          })
    }

    const getDogs =async()=>{
        
        for(let i = 0; i < questionList.length; i++){
  
            getImage(i)
        }
      }

      // only execute at start
      if(!initialRef.current){
        initialRef.current = true
  
        
      }

 
    const showAnswer =()=> {
        setDone(true)
    }

    const finishQuestion =()=> {

        showAnswer()

  
        setTimeout(nextImage, 1000)
    
    }
    
    const timerDone=()=>{

        if(!stopTime){
            finishQuestion()
            setOrange(true)
        }
            
    }


    const writeScore=(points, score)=>{
        const baseUrl = serverUrl + 'game/'
            
        const request = axios.post(baseUrl + 'stats', 
        {
            'num_correct': score,
            'num_questions': numQuestions,
            'mode': gameMode,
            'score': points
        },
        { withCredentials:true})
            
      

        request.then(response=>{
            if(response.status === 200){
              setLoggedIn(true)
            }
            else if(response.status === 201){
                setHighScore(true)
                setLoggedIn(true)
            }
            else{
              
            }
            setEndGame(true) // show results
        })
        .catch(function (error) {
           
            setEndGame(true) 
        })

        
    }
    
    const wrongAnswer=(e, choice)=>{
        if(done)
            return

        if(endQuiz.current)
            return

        if(choice !== questionList[index].answerIndex){
          setChosen(e.target.id)
          
        }
        else{
            setScore(score + 1)
            finalScore.current += 1

            if(gameMode === 1)
                newPoints.current = 100
            else if(gameMode === 2 || gameMode === 4)
                newPoints.current = 150
            else
                newPoints.current = 200


            setPoints(points + newPoints.current)
            finalPoints.current += newPoints.current

        }

        setStop(true) //stop timer if button pressed
            
        finishQuestion()
    }

    useEffect(() => {
        getDogs()
        
        // eslint-disable-next-line
      }, [])


    useEffect(() => {

        scrollToBottom()
        
    }, [index, imageReady])

    
    if(failure){
        return <div style={{textAlign:'center'}}>
            <div className = "loadingScreen">There was a problem loading your game. 
        Check your internet connection or try refreshing the page.</div>
        </div>
    }

    if(!imageReady){
       
        return <div style={{textAlign:'center'}}>
            <div className = "loadingScreen">Loading...</div>
            </div>
    }


    return(endGame?
        <div className='' style={{flex:'.6 1 0'}}>
            <div className='results ' >
          
                <div className='innerResults' >
                   
                    <h1 style={{textAlign:'center', fontSize:'2.5rem'}}>Final Score</h1><br/>

                    <div style={{alignSelf:'center', width:'fit-content'}}>
                        <h2 style={{fontSize:'2rem'}}>{modeName.current} Mode</h2>
                        <h2 style={{fontSize:'2rem'}}>{score} / {questionList.length}</h2>
                    </div>
                
                    {
                        loggedIn? 
                            highScore? 
                            <div className='resultsText resultsColor'>
                                New High Score!
                            </div>
                            : 
                            <div className='resultsText resultsColor' >
                                &nbsp;
                            </div>

                        : 
                        <div className='resultsText resultsColor'>
                            Log in to save your high scores
                        </div>
                    }
                    </div>
             
            </div>
           
            </div>
        :
        <div className='gameDiv' style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', flex:'1 1 0'}}>
            
            
            <div className = "image1" >
                <div className = "trackerBox">
                    <div className="hiddenTracker">
                        Question {numQuestions}
                        <br/><br/>
                        10 seconds
                    </div>
                            
                    <div className = "tracker">
                        Question {index + 1}
                        
                        <br/><br/>
                        <Timer limit = {10} endFunction={timerDone} index={index} stopTime = {stopTime}>
                            
                        </Timer>
                        
                    </div>
                </div>

                <div className='dogContainer'>
                    <img src ={images.current[index]} alt = "dog" className = "dogImage"/>
                </div>
                
                <div className="trackerBoxRight">
                    <div className="hiddenTracker">
                        Question {numQuestions}
                        <br/><br/>
                        10 seconds
                    </div>

                    <div className="rightInfo">
                        
                        {modeName.current}
                        <br/><br/>
                      {score} / {questionList.length}
                      {/* Score: {points} */}
                    </div>
                </div>
            </div>

            <div id = "choice-container">
                <button id="one" className = {(questionList[index].answerIndex === 0 && done === true)? orange === true? 
                "buttonTop orange" :  "buttonTop right" :
                    chosen === "one"? "buttonTop wrong" : "buttonTop"} onClick={(e)=>wrongAnswer(e, 0)}>{questionList[index].choices[0]}</button>
                <button id="two" className = {(questionList[index].answerIndex === 1 && done === true)? orange === true?  
                "buttonTop orange" :  "buttonTop right" :
                    chosen === "two"? "buttonTop wrong" : "buttonTop"} onClick={(e)=>wrongAnswer(e, 1)}>{questionList[index].choices[1]}</button>
                <button id ="three" className = {(questionList[index].answerIndex === 2 && done === true)? orange === true? 
                "buttonTop orange" :  "buttonTop right" :
                    chosen === "three"? "buttonTop wrong" : "buttonTop"} onClick={(e)=>wrongAnswer(e, 2)}>{questionList[index].choices[2]}</button>
                <button id="four" className = {(questionList[index].answerIndex === 3 && done === true)? orange === true? 
                "buttonTop orange" :  "buttonTop right" :
                    chosen === "four"? "buttonTop wrong" : "buttonTop"} onClick={(e)=>wrongAnswer(e, 3)}>{questionList[index].choices[3]}</button>
            </div>

        </div>
    )
}

export default ChoiceGrid

