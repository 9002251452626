


const AchievementList =({achievementArray})=>{



    return(
        <div className="allMedals">
             <div className="flexMedal">
            
             <div className = { `quiz-medal fitContent  ${achievementArray.some((item)=>item.achievement_id === 1)?
                ``
                :
                `halfTransparent`
            }`}>
                <div className="quiz-medal__circle quiz-medal__circle--bronze">
                </div>
                <div className="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
                <div className="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
                </div>
               
                <div className="expand fitContent ">
                <b>Bronze Medal</b>
                <p className="noBottom">Get a perfect score with 10, 20, and 30 questions on Easy mode</p>
                </div>
            </div>

            <br/>
    
            <div className="flexMedal">
            
            <div className = { `quiz-medal fitContent  ${achievementArray.some((item)=>item.achievement_id === 2)?
                ``
                :
                `halfTransparent`
            }`}>
            <div className="quiz-medal__circle quiz-medal__circle--silver">
            </div>
            <div className="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
            <div className="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
            </div>
           
            <div className="expand fitContent">
            <b>Silver Medal</b>
            <p className="noBottom">Get a perfect score with 10, 20, and 30 questions on Normal mode</p>

            </div>
        </div>
            <br/>
    
            <div className="flexMedal">
            
            <div className = { `quiz-medal fitContent  ${achievementArray.some((item)=>item.achievement_id === 3)?
                ``
                :
                `halfTransparent`
            }`}>
            <div className="quiz-medal__circle quiz-medal__circle--gold">
            </div>
            <div className="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
            <div className="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
            </div>
           
            <div className="expand fitContent">
            <b>Gold Medal</b>
            <p className="noBottom">Get a perfect score with 10, 20, and 30 questions on Hard mode</p>

            </div>
        </div>
            <br/>
    
            <div className="flexMedal">
            
            <div className = { `quiz-medal fitContent  ${achievementArray.some((item)=>item.achievement_id === 4)?
                ``
                :
                `halfTransparent`
            }`}>
            <div className="quiz-medal__circle quiz-medal__circle--platinum">
            </div>
            <div className="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
            <div className="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
            </div>
           
            <div className="expand fitContent">
            <b>Platinum Medal</b>
            <p className="noBottom">Get a perfect score on All Dog mode</p>

            </div>
        </div>
            <br/>
        </div>
     )
    }
    
    export default AchievementList